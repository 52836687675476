import * as React from "react"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Link from "@mui/material/Link"
import Tooltip from "@mui/material/Tooltip"
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined"
import VerifiedIcon from "@mui/icons-material/Verified"
import { IStudent } from "../../Services/utils"
import {
  ACTION_CHECK_OUT,
  ACTION_SELECT,
  ACTION_UNSELECT,
} from "../../Services/config"
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning"

export interface IItemProps {
  student: IStudent
  adminName: string
  isVisible: boolean
  action: string
  setActionModal: React.Dispatch<React.SetStateAction<string>>
  openConfirmationModal: (student: IStudent) => void
  isCheckBoxSelected: boolean
  verifyGuardian: (student: IStudent) => void
}

const showNote = (note: string) => (
  <div className="font-normal flex text-sm text-blue-600">
    <NotesOutlinedIcon sx={{ fontSize: "19px" }} color="success" />
    {note}
  </div>
)

export default function Item(props: IItemProps) {
  const {
    student,
    action,
    adminName,
    isVisible,
    setActionModal,
    openConfirmationModal,
    isCheckBoxSelected,
    verifyGuardian,
  } = props

  const {
    firstName,
    surname,
    class: classroom,
    parentFirst,
    parentLast,
    people,
    note,
  } = student
  const withParentLastName = parentLast ? ` ${parentLast}` : ""
  const withPeople = people ? `, ${people}` : ""
  const guardian = `${parentFirst}${withParentLastName}${withPeople}`

  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setActionModal(ACTION_SELECT)
    } else {
      setActionModal(ACTION_UNSELECT)
    }
    openConfirmationModal(student)
  }

  const boxBgColor = isCheckBoxSelected ? "bg-green-300" : ""

  const renderStudentStatus = () => {
    if (action === ACTION_CHECK_OUT && student.isSignedIn !== "Yes") {
      return (
        <FormGroup aria-label="position" row>
          <h4 className="text-gray-300">not signed in</h4>
        </FormGroup>
      )
    } else if (
      action === ACTION_CHECK_OUT &&
      student.note?.includes("leave early")
    ) {
      return (
        <FormGroup aria-label="position" row>
          <h4 className="text-gray-300">leave early</h4>
        </FormGroup>
      )
    } else if (
      action === ACTION_CHECK_OUT &&
      student.note?.includes("not joining bus home")
    ) {
      return (
        <FormGroup aria-label="position" row>
          <h4 className="text-gray-300">not joining bus home</h4>
        </FormGroup>
      )
    } else {
      return (
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                checked={isCheckBoxSelected}
                onChange={onChangeCheckbox}
              />
            }
            label={
              <span className="text-sm">
                {isCheckBoxSelected ? adminName : action}
              </span>
            }
            labelPlacement="top"
            sx={{ m: 0, mr: 1 }}
          />
        </FormGroup>
      )
    }
  }

  return (
    <>
      {isVisible && (
        <div
          className={
            boxBgColor + " py-1 mb-1 border border-solid border-gray-400 "
          }
        >
          <div className="flex px-2 py-1 items-center">
            <div className="flex-1 w-full">
              <div className="font-semibold flex">
                <div className="flex-1">
                  {`${firstName} ${surname}`}{" "}
                  <span className="font-normal">({classroom})</span>
                </div>
              </div>
              <div className="font-light flex text-sm">
                <EscalatorWarningIcon
                  sx={{ fontSize: "medium" }}
                  color="action"
                />
                {guardian}
                {student.isSignedOutGuardianVerified &&
                  action === ACTION_CHECK_OUT && (
                    <div className="font-normal flex text-sm text-green-600 mx-1">
                      <Tooltip title="Guardian is verified" placement="right">
                        <VerifiedIcon fontSize="small" />
                      </Tooltip>
                    </div>
                  )}
              </div>
              {note !== undefined && note !== "" && showNote(note)}
              {action === ACTION_CHECK_OUT &&
                !student.isSignedOutGuardianVerified &&
                student.isSignedIn === "Yes" && (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => verifyGuardian(student)}
                  >
                    Verify Guardian
                  </Link>
                )}
            </div>
            <div className="flex-1/2">{renderStudentStatus()}</div>
          </div>
        </div>
      )}
    </>
  )
}

import * as React from "react"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import Grid from "@mui/material/Grid"
import CircularProgress from "@mui/material/CircularProgress"
import {
  getAdminWhoPerformAction,
  convertDayIntoAPIUrlFormat,
  IStudent,
  processStudentsResponseByClass,
  getStatistics,
} from "../Services/utils"
import Item from "./Content/Item"
import { useGetStudentsQuery } from "../Services/useGetStudentsQuery"
import { Guide } from "./Content/Guide"
import { useFilterContext } from "./Context/FilterProvider"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useUpdateStudentMutation } from "../Services/useUpdateStudentMutation"
import { useQueryClient } from "react-query"
import {
  ACTION_CHECK_IN,
  ACTION_CHECK_OUT,
  ACTION_SELECT,
  ACTION_UNSELECT,
  YEAR,
} from "../Services/config"
import dayjs from "dayjs"
import ConfirmationModal from "./Content/ConfirmationModal"
import { useAuth } from "./Auth/AuthProvider"
import VerifiedIcon from "@mui/icons-material/Verified"
import { QrReader } from "./QR/QrReader"
import CloseIcon from "@mui/icons-material/Close"
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning"
import ContactMailIcon from "@mui/icons-material/ContactMail"

export interface QRValues {
  guardianEmail: string
  year?: number
}

export const ContentSection: React.FC = () => {
  const { day, filter, location, searchQuery, action } = useFilterContext()
  const { attendanceAdmin } = useAuth()
  const [students, setStudents] = React.useState<IStudent[]>([])
  const [actionModal, setActionModal] = React.useState("")
  const [isVisibleWebcam, setVisibleWebcam] = React.useState(false)
  const [isVisibleGuardianLoader, setVisibleGuardianLoader] =
    React.useState(false)
  const [newStudent, setNewStudent] = React.useState<IStudent>()
  const [visibleConfirmationModal, setVisibleConfirmationModal] =
    React.useState(false)
  const [startRefetchInterval, setStartRefetchInterval] = React.useState(true)
  const [isVisibleToast, setVisibleToast] = React.useState(false)
  const [QRScanResult, setQRScanResult] = React.useState<QRValues>()
  const [showQRError, setShowQRError] = React.useState(false)

  const {
    isLoading,
    data: fetchedData,
    isRefetching,
    isPreviousData,
  } = useGetStudentsQuery(
    `${location.toLowerCase()}${convertDayIntoAPIUrlFormat(day)}`,
    startRefetchInterval
  )

  const onSuccessUpdateStudent = () => {
    setVisibleGuardianLoader(false)
    handleCloseModal()
    setStartRefetchInterval(true)
  }

  const { isLoading: isUpdating, mutate } = useUpdateStudentMutation(
    useQueryClient(),
    onSuccessUpdateStudent
  )

  React.useEffect(() => {
    if (!isPreviousData) {
      const studentsByClass = processStudentsResponseByClass(
        fetchedData,
        filter
      )
      setStudents(studentsByClass)
    }
  }, [fetchedData, filter, action, day])

  const handleCloseModal = () => setVisibleConfirmationModal(false)
  const handleCloseToast = () => setVisibleToast(false)
  const handleCloseWebcam = async () => {
    setQRScanResult(undefined)
    setVisibleWebcam(false)
  }

  const mutateStudent = async (newStudent: IStudent) => {
    const dataKey = `${location.toLowerCase()}${convertDayIntoAPIUrlFormat(
      day
    )}`

    await mutate({
      day,
      dataKey,
      newStudent,
      url: `${process.env.REACT_APP_API_BASE_URL}/${dataKey}/${newStudent.id}`,
    })
  }

  const handleUpdateStudent = async (newStudent: IStudent) => {
    setStartRefetchInterval(false)
    if (actionModal === ACTION_UNSELECT) {
      if (action === ACTION_CHECK_IN) {
        newStudent.signInLastUpdatedTime = dayjs().format(
          "DD/MM/YYYY, h:mm:ss a"
        )
        newStudent.isSignedIn = "No"
        newStudent.adminIn = attendanceAdmin
      } else {
        newStudent.signOutLastUpdatedTime = dayjs().format(
          "DD/MM/YYYY, h:mm:ss a"
        )
        newStudent.isSignedOut = "No"
        newStudent.adminOut = attendanceAdmin
      }
    } else if (actionModal === ACTION_SELECT) {
      if (action === ACTION_CHECK_IN) {
        newStudent.signInLastUpdatedTime = dayjs().format(
          "DD/MM/YYYY, h:mm:ss a"
        )
        newStudent.isSignedIn = "Yes"
        newStudent.adminIn = attendanceAdmin
      } else {
        newStudent.signOutLastUpdatedTime = dayjs().format(
          "DD/MM/YYYY, h:mm:ss a"
        )
        newStudent.isSignedOut = "Yes"
        newStudent.adminOut = attendanceAdmin
      }
    }

    await mutateStudent(newStudent)
  }

  const openConfirmationModal = (updateStudent: IStudent) => {
    setVisibleConfirmationModal(true)
    setNewStudent(updateStudent)
  }

  const { totalChecked, totalExpectedStudents } = getStatistics(
    students,
    day,
    action
  )

  const successToastMessage = (
    <>
      Data for{" "}
      <b>
        {newStudent?.firstName} {newStudent?.surname}
      </b>{" "}
      ({newStudent?.class}) is updated!
    </>
  )

  const verifyGuardian = (updateStudent: IStudent) => {
    setNewStudent(updateStudent)
    setVisibleWebcam(true)
  }

  const isGuardianVerified =
    QRScanResult?.guardianEmail &&
    QRScanResult?.guardianEmail === newStudent?.guardianEmail &&
    QRScanResult?.year === YEAR

  const withParentLastName = newStudent?.parentLast
    ? ` ${newStudent?.parentLast}`
    : ""
  const withPeople = newStudent?.people ? `, ${newStudent?.people}` : ""
  const guardian = `${newStudent?.parentFirst}${withParentLastName}${withPeople}`

  const saveGuardianVerificationStatus = async (newStudent: IStudent) => {
    setVisibleGuardianLoader(true)
    newStudent.guardianSignoutLastUpdatedTime = dayjs().format(
      "DD/MM/YYYY, h:mm:ss a"
    )
    newStudent.isSignedOutGuardianVerified = "Yes"
    newStudent.guardianAdminSignoutScanner = attendanceAdmin
    await mutateStudent(newStudent)
  }

  return (
    <div className="z-0">
      <Dialog open={isVisibleToast} onClose={handleCloseToast}>
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            <CheckCircleIcon color="success" fontSize="large" />
          </div>
          <div className="content-center text-base">{successToastMessage}</div>
          <DialogActions>
            <Button
              onClick={handleCloseToast}
              color="primary"
              variant="outlined"
            >
              OK
            </Button>
          </DialogActions>
        </DialogTitle>
      </Dialog>

      <Dialog open={isVisibleGuardianLoader}>
        <DialogTitle id="alert-dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            <CircularProgress color="success" />
          </div>
          <div className="content-center">Updating Google Sheet</div>
        </DialogTitle>
      </Dialog>

      {newStudent && visibleConfirmationModal && (
        <ConfirmationModal
          name={`${newStudent.firstName} ${newStudent.surname}`}
          action={action}
          urgentNote={newStudent.urgentNote}
          handleClose={handleCloseModal}
          handleYes={() => handleUpdateStudent(newStudent)}
          isVisibleModal={visibleConfirmationModal}
          isUpdating={isUpdating}
          actionModal={actionModal}
          isSignedOutGuardianVerified={newStudent.isSignedOutGuardianVerified}
          isSignedIn={newStudent.isSignedIn}
        />
      )}

      <Dialog
        open={isVisibleWebcam}
        onClose={handleCloseWebcam}
        maxWidth="sm"
        sx={{ px: 1 }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{ px: 1, backgroundColor: "green", color: "white" }}
        >
          <Box flexGrow={1}>
            {isGuardianVerified ? (
              <>
                <b>Verification is successful!</b>
              </>
            ) : (
              <>
                <div>
                  Guardian QR for{" "}
                  <b>
                    {newStudent?.firstName} {newStudent?.surname}.
                  </b>
                </div>
                <div>
                  <EscalatorWarningIcon
                    sx={{ fontSize: "medium", color: "white" }}
                  />{" "}
                  {guardian}
                </div>
                <div>
                  <ContactMailIcon
                    sx={{ fontSize: "medium", color: "white" }}
                  />{" "}
                  {newStudent?.guardianEmail}
                </div>
              </>
            )}
          </Box>
          <Box>
            <IconButton aria-label="close" onClick={handleCloseWebcam}>
              <CloseIcon sx={{ color: "white" }} />
            </IconButton>
          </Box>
        </Box>

        {!isGuardianVerified && showQRError && (
          <p
            style={{
              position: "absolute",
              top: "25%",
              left: "25%",
              zIndex: 99999,
              color: "red",
            }}
          >
            <Box sx={{ px: 1, backgroundColor: "red", color: "white" }}>
              Invalid QR code! Please scan QR code from PDF.
            </Box>
          </p>
        )}

        {!isGuardianVerified && QRScanResult && (
          <p
            style={{
              position: "absolute",
              top: "25%",
              left: "25%",
              zIndex: 99999,
              color: "red",
            }}
          >
            <Box sx={{ px: 1, backgroundColor: "red", color: "white" }}>
              Invalid QR code! Please check the email again.
            </Box>
          </p>
        )}
        <div className="content-center text-base">
          {!isGuardianVerified && (
            <QrReader
              setQRScanResult={setQRScanResult}
              setShowQRError={setShowQRError}
            />
          )}

          {isGuardianVerified ? (
            <>
              <div
                style={{ margin: "5px", padding: "10px", textAlign: "left" }}
              >
                <div style={{ textAlign: "center", margin: "20px 20px" }}>
                  <VerifiedIcon fontSize="large"></VerifiedIcon>
                </div>
                <div>
                  Guardian for{" "}
                  <b>
                    {newStudent?.firstName} {newStudent?.surname}
                  </b>{" "}
                  is <b>verified.</b>{" "}
                </div>
              </div>

              <DialogActions sx={{ margin: "5px" }}>
                <Button
                  onClick={() => {
                    saveGuardianVerificationStatus(newStudent)
                    handleCloseWebcam()
                  }}
                  color="primary"
                  variant="outlined"
                >
                  OK
                </Button>
              </DialogActions>
            </>
          ) : (
            ""
          )}
        </div>
      </Dialog>

      <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <List>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <hr className="border border-dotted border-green-400" />
                  <Guide
                    isLoading={isLoading}
                    isRefetching={isRefetching}
                    totalChecked={totalChecked}
                    totalExpectedStudents={totalExpectedStudents}
                  />
                  <hr className="border border-dotted border-green-400" />
                  {students.map((student: IStudent) => {
                    const isVisible =
                      (student?.firstName
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                        student?.surname
                          ?.toLowerCase()
                          .includes(searchQuery.toLowerCase())) ??
                      true
                    const isCheckBoxSelected = () => {
                      if (action === ACTION_CHECK_IN) {
                        return student.isSignedIn === "Yes"
                      } else if (action === ACTION_CHECK_OUT) {
                        return student.isSignedOut === "Yes"
                      }
                      return false
                    }

                    return (
                      <Item
                        key={student.id}
                        student={student}
                        action={action}
                        adminName={getAdminWhoPerformAction(
                          student,
                          attendanceAdmin,
                          action
                        )}
                        isVisible={isVisible}
                        setActionModal={setActionModal}
                        openConfirmationModal={openConfirmationModal}
                        verifyGuardian={verifyGuardian}
                        isCheckBoxSelected={isCheckBoxSelected()}
                      />
                    )
                  })}
                </>
              )}
            </List>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

import Typography from "@mui/material/Typography"
import React from "react"

export interface IHeaderProps {
  title: string
  day: string
  isDayVisible: boolean
}

export function Header(props: IHeaderProps) {
  const { title, day, isDayVisible } = props

  return (
    <>
      <div className="text-center  text-gray-600 w-full mt-2">
        <Typography variant="h5" component="h5" mt="1">
          {title}{" "}
          {isDayVisible && (
            <>
              - <span>{day.toUpperCase()}</span>
            </>
          )}
        </Typography>
      </div>
    </>
  )
}

import React from "react"

interface AuthContextType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  password: any
  signin: (
    admin: string,
    password: string,
    onSuccess: VoidFunction,
    onFail: VoidFunction
  ) => void
  signout: (callback: VoidFunction) => void
  attendanceAdmin: string
  setAttendanceAdmin: (admin: string) => void
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = React.createContext<AuthContextType>(null!)

export const useAuth = () => {
  return React.useContext(AuthContext)
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [attendanceAdmin, setAttendanceAdmin] = React.useState("")
  // localStorage.getItem("attendanceAdmin") || ""
  // )
  const [password, setPassword] = React.useState("")
  // localStorage.getItem("password") || ""
  // )

  const signin = (
    admin: string,
    newPassword: string,
    onSuccess: VoidFunction,
    onFail: VoidFunction
  ) => {
    if (newPassword === process.env.REACT_APP_ACCESS_PWD) {
      setAttendanceAdmin(admin)
      setPassword(newPassword)
      onSuccess()
    } else {
      setPassword("")
      onFail()
    }
  }

  const signout = (callback: VoidFunction) => {
    setPassword("")
    callback()
  }

  const value = {
    attendanceAdmin,
    password,
    signin,
    signout,
    setAttendanceAdmin,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

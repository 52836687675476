import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import React from "react"

export default function Copyright() {
  return (
    <div className="mt-11 text-center">
      <Typography
        variant="caption"
        sx={{
          color: "gray",
          fontWeight: "light",
          fontSize: 11,
        }}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://www.irecmelbourne.org/">
          IREC MELBOURNE
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  )
}

import {
  IAttandanceLabelValue,
  ILabelValue,
  LOCATION_EAST,
  LOCATION_WEST,
} from "./utils"

export const ALL = { label: "All", value: "All" }
export const LOWER_CLASS_LABEL = { label: "Lower", value: "Lower" }
export const MIDDLER_CLASS_LABEL = { label: "Middler", value: "Middler" }
export const UPPER_CLASS_LABEL = { label: "Upper", value: "Upper" }

export const listFilterClass: ILabelValue[] = [
  ALL,
  LOWER_CLASS_LABEL,
  MIDDLER_CLASS_LABEL,
  UPPER_CLASS_LABEL,
]

export const TITLE = "VBS"

export const INPUT_LOCATION_WEST = {
  label: LOCATION_WEST,
  value: LOCATION_WEST,
}
export const INPUT_LOCATION_EAST = {
  label: LOCATION_EAST,
  value: LOCATION_EAST,
}

export const listAttendanceAdmins: IAttandanceLabelValue[] = [
  {
    label: "Elisa",
    value: "Elisa",
    location: [INPUT_LOCATION_WEST, INPUT_LOCATION_EAST],
  },
  {
    label: "David",
    value: "David",
    location: [INPUT_LOCATION_WEST, INPUT_LOCATION_EAST],
  },
]

export const YEAR = new Date().getFullYear()

export const listDaysWest: ILabelValue[] = [
  { label: "Day 1, 28 June 2023", value: "Day 1" },
  { label: "Day 2, 29 June 2023", value: "Day 2" },
  { label: "Day 3, 30 June 2023", value: "Day 3" },
]

export const listDaysEast: ILabelValue[] = [
  { label: "Day 1, 3 July 2024", value: "Day 1" },
  { label: "Day 2, 4 July 2024", value: "Day 2" },
  { label: "Day 3, 5 July 2024", value: "Day 3" },
]

export const ACTION_SELECT = "Select"
export const ACTION_UNSELECT = "Unselect"

export const ACTION_CHECK_IN = "Sign in"
export const ACTION_CHECK_OUT = "Sign out"

export const APP_PREFERENCES = {
  defaultDay: "Day 3",
  defaultLocation: LOCATION_EAST,
}

import { IStudent } from "./utils"

const headers = () => {
  const headers = new Headers()
  headers.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`
  )
  headers.append("Content-type", "application/json")
  return headers
}

export const getStudentApi = (url: string) => {
  return fetch(url, {
    method: "GET",
    headers: headers(),
  }).then((response) => {
    if (!response.ok) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
    return response.json()
  })
}

export const getAdminList = () => {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/adminList`, {
    method: "GET",
    headers: headers(),
  }).then((response) => {
    if (!response.ok) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
    return response.json()
  })
}

export const updateStudentApi = (
  url: string,
  body: {
    [x: string]: IStudent
  }
) => {
  return fetch(url, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(body),
  }).then((response) => {
    if (!response.ok) {
      return response.text().then((text) => {
        throw new Error(text)
      })
    }
    return response.json()
  })
}

import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import React from "react"
import { ACTION_CHECK_IN, ACTION_CHECK_OUT } from "../Services/config"
import { ILabelValue } from "../Services/utils"
import { useFilterContext } from "./Context/FilterProvider"
import FormHelperText from "@mui/material/FormHelperText"
import TextField from "@mui/material/TextField"

interface FilterSectionProps {
  onFilterChange: (event: SelectChangeEvent) => void
  listFilters: ILabelValue[]
}

export default function FilterSection(props: FilterSectionProps) {
  const { onFilterChange, listFilters } = props
  const { filter, action, setAction, setSearchQuery, searchQuery } =
    useFilterContext()

  return (
    <>
      <div className="my-2 ">
        <FormControl size="small" fullWidth className="my-1">
          <InputLabel id="select-location-small">Class</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            label="Filter"
            onChange={onFilterChange}
            defaultValue={filter}
          >
            {listFilters.map((filter) => (
              <MenuItem value={filter.value} key={filter.label}>
                {filter.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" fullWidth className="mt-1">
          <InputLabel id="select-day-small">Action *</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={action}
            label="Action"
            onChange={(event: SelectChangeEvent) => {
              setAction(event.target.value as string)
              window.scrollTo(0, 0)
            }}
          >
            <MenuItem value={ACTION_CHECK_IN} key={ACTION_CHECK_IN}>
              {ACTION_CHECK_IN}
            </MenuItem>
            <MenuItem value={ACTION_CHECK_OUT} key={ACTION_CHECK_OUT}>
              {ACTION_CHECK_OUT}
            </MenuItem>
          </Select>
          {action === "" && <FormHelperText>Required</FormHelperText>}
        </FormControl>
      </div>
      {filter && action && (
        <FormControl size="small" fullWidth className="mb-2">
          <TextField
            id="outlined-search"
            label="Search student by First name or Surname"
            type="search"
            defaultValue={searchQuery}
            size="small"
            onChange={(event) => {
              setSearchQuery(event.target.value as string)
              window.scrollTo(0, 0)
            }}
          />
        </FormControl>
      )}
    </>
  )
}

import { useQuery } from "react-query"
import { getStudentApi } from "./api"

export const useGetStudentsQuery = (
  dataKey: string,
  startRefetchInterval: boolean
) => {
  return useQuery(
    ["fetchStudents", dataKey],
    () => {
      const url = `${process.env.REACT_APP_API_BASE_URL}/${dataKey}`
      return getStudentApi(url)
        .then((data) => data[`${dataKey}`])
        .catch((error) => console.error(error))
    },
    {
      refetchInterval: startRefetchInterval ? 30000 : false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )
}

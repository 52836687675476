import * as React from "react"
import { useFilterContext } from "../Components/Context/FilterProvider"
import { useParams } from "react-router-dom"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import { Header } from "../Components/Header/Header"
import { TITLE } from "../Services/config"
import { LoginDetails } from "../Components/Header/LoginDetails"

export const CheckPage = () => {
  const { name } = useParams()
  const { setLocation, setDay, day } = useFilterContext()

  React.useEffect(() => {
    setLocation("")
    setDay("")
  })

  const checkIn = () => {
    console.log(`Check in ${name}`)
  }

  const checkOut = () => {
    console.log(`Check out ${name}`)
  }
  return (
    <Container maxWidth="sm">
      <Header title={`${TITLE}`} day={day} isDayVisible={false} />
      <LoginDetails />
      <div className="my-3">
        <h3>Action for {name}</h3>
        <div>
          <Button
            onClick={checkIn}
            color="primary"
            variant="contained"
            className="mx-2"
          >
            check in
          </Button>
          <Button onClick={checkOut} color="secondary" variant="contained">
            check out
          </Button>
        </div>
      </div>
    </Container>
  )
}

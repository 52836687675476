import * as React from "react"
import { useEffect, useRef, useState } from "react"

// Styles
import "./QrStyles.css"

// Qr Scanner
import QrScanner from "qr-scanner"
import { QRValues } from "../ContentSection"

export interface IPropsQrReader {
  setQRScanResult: React.Dispatch<React.SetStateAction<QRValues | undefined>>
  setShowQRError: React.Dispatch<React.SetStateAction<boolean>>
}

export const QrReader = (props: IPropsQrReader) => {
  const { setQRScanResult, setShowQRError } = props
  // QR States
  const scanner = useRef<QrScanner>()
  const videoEl = useRef<HTMLVideoElement>(null)
  // const qrBoxEl = useRef<HTMLDivElement>(null)
  const [qrOn, setQrOn] = useState<boolean>(true)

  // Success
  const onScanSuccess = (result: QrScanner.ScanResult) => {
    console.log("onScanSuccess", result?.data)
    try {
      const parsedResult = JSON.parse(result?.data)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isQRValues = (obj: any) => {
        // Replace 'prop1', 'prop2', etc. with actual properties of QRValues
        return (
          obj &&
          typeof obj === "object" &&
          "guardianEmail" in obj &&
          typeof obj.guardianEmail === "string" &&
          ("year" in obj ? typeof obj.year === "number" : true)
        )
      }

      if (isQRValues(parsedResult)) {
        setQRScanResult(parsedResult)
      } else {
        setShowQRError(true)
      }
    } catch (error) {
      setShowQRError(true)
    }
  }

  // Fail
  const onScanFail = (err: string | Error) => {
    // 🖨 Print the "err" to browser console.
    console.log(err)
  }

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      setQRScanResult(undefined)
      setShowQRError(false)

      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        // overlay: qrBoxEl?.current || undefined,
      })

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false)
        })
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop()
      }
    }
  }, [])

  return (
    <div className="qr-reader">
      {/* QR */}
      <video ref={videoEl}></video>
      {!qrOn
        ? "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
        : ""}
    </div>
  )
}

import Container from "@mui/material/Container"
import * as React from "react"
import Copyright from "../../Components/Footer/Copyright"

export interface MainProps {
  children: React.ReactNode
}

export const Main: React.FC<MainProps> = ({ children }) => {
  return (
    <Container maxWidth="sm">
      {children}
      <Copyright />
    </Container>
  )
}

import { ACTION_CHECK_IN, ACTION_CHECK_OUT, ALL } from "./config"

export interface IStudent {
  id: number
  firstName: string
  surname: string
  class: string
  filter: string
  parentFirst: string
  parentLast: string
  people: string
  note?: string
  urgentNote?: string
  isSignedIn?: string
  isSignedOut?: string
  isSignedOutGuardianVerified?: string
  guardianAdminSignoutScanner?: string
  guardianSignoutLastUpdatedTime?: string
  signInLastUpdatedTime?: string
  signOutLastUpdatedTime?: string
  adminIn?: string
  adminOut?: string
  guardianEmail?: string
}

export interface IStudentMutationUpdate {
  day: string
  newStudent: IStudent
  url: string
  dataKey: string
}

export interface ILabelValue {
  label: string
  value: string
}

export interface IAttandanceLabelValue {
  label: string
  value: string
  location: ILabelValue[]
}

export const convertDayIntoAPIUrlFormat = (day: string) => {
  if (day.includes("Day 1")) return "Day1"
  else if (day.includes("Day 2")) return "Day2"
  else if (day.includes("Day 3")) return "Day3"
}

export const getAdminWhoPerformAction = (
  student: IStudent,
  attendanceAdmin: string,
  action: string
) => {
  const adminInName = (student.adminIn as string) || attendanceAdmin
  const adminOutName = (student.adminOut as string) || attendanceAdmin

  return action === ACTION_CHECK_IN ? adminInName : adminOutName
}

// todo
export const sortStudents = (students: IStudent[], action: string) => {
  const signedInStudents = students
    .filter((s) => s.isSignedIn === "Yes")
    .reverse()
  const notSignedInStudents = students.filter(
    (s) =>
      s.isSignedIn === "" || s.isSignedIn === "No" || s.isSignedIn === undefined
  )
  const signedOutStudents = students
    .filter((s) => s.isSignedOut === "Yes")
    .reverse()
  const notSignedOutStudents = students.filter(
    (s) =>
      s.isSignedOut === "" ||
      s.isSignedOut === "No" ||
      s.isSignedOut === undefined
  )

  if (action === ACTION_CHECK_IN) {
    return notSignedInStudents.concat(signedInStudents)
  } else if (action === ACTION_CHECK_OUT) {
    return notSignedOutStudents.concat(signedOutStudents)
  } else {
    return students
  }
}

export const processStudentsResponseByClass = (
  studentsResponse: IStudent[],
  filter: string
) => {
  if (studentsResponse) {
    return filter !== ALL.label
      ? studentsResponse.filter((s) => s.filter === filter)
      : studentsResponse
  }

  return []
}

export const getStatistics = (
  students: IStudent[],
  day: string,
  action: string
) => {
  const totalChecked =
    action === ACTION_CHECK_IN
      ? students.filter((student) => student.isSignedIn === "Yes").length
      : students.filter((student) => student.isSignedOut === "Yes").length
  return {
    totalChecked,
    totalExpectedStudents: students.length,
  }
}

export const LOCATION_WEST = "West"
export const LOCATION_EAST = "East"

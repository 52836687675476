import * as React from "react"
import { SelectChangeEvent } from "@mui/material/Select"
import FilterSection from "../Components/FilterSection"
import { Main } from "./Layouts/Main"
import { listFilterClass } from "../Services/config"
import { ContentSection } from "../Components/ContentSection"
import { useFilterContext } from "../Components/Context/FilterProvider"
import { useAuth } from "../Components/Auth/AuthProvider"
import { useParams } from "react-router-dom"
import { LoginDetails } from "../Components/Header/LoginDetails"
import { useIdleTimer } from "react-idle-timer"

export default function MainPage() {
  const { paramFilter } = useParams()
  const queryParams = new URLSearchParams(location.search)

  // Extracting query parameters
  const studentName = queryParams.get("studentName")
  const { setFilter, action, setSearchQuery } = useFilterContext()
  const { attendanceAdmin, signout } = useAuth()

  // automatic logout onIdle after 60 minutes
  const onIdle = () => {
    signout(() => {
      window.location.href = "/"
    })
  }
  useIdleTimer({
    onIdle,
    timeout: 3600000, // 60 minutes
    throttle: 500,
  })

  React.useEffect(() => {
    if (paramFilter)
      setFilter(paramFilter.charAt(0).toUpperCase() + paramFilter.slice(1))
    if (studentName) setSearchQuery(studentName)
  }, [])

  const isAdminAndActionSelected = attendanceAdmin && action

  return (
    <Main>
      <div className="sticky top-0 z-20 bg-white">
        <LoginDetails />
        <FilterSection
          onFilterChange={(event: SelectChangeEvent) => {
            setFilter(event.target.value as string)
            window.scrollTo(0, 0)
          }}
          listFilters={listFilterClass}
        />
      </div>

      {isAdminAndActionSelected ? <ContentSection /> : null}
    </Main>
  )
}

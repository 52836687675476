import * as React from "react"
import { Main } from "./Layouts/Main"

export default function NoPage() {
  return (
    <Main>
      <h1>404 — Page not found</h1>
    </Main>
  )
}

import { useAuth } from "../Auth/AuthProvider"
import * as React from "react"
import { Header } from "../../Components/Header/Header"
import { TITLE, YEAR } from "../../Services/config"
import { useFilterContext } from "../Context/FilterProvider"

export const LoginDetails = () => {
  const { attendanceAdmin } = useAuth()
  const { signout } = useAuth()
  const { day } = useFilterContext()
  return (
    <>
      <Header title={`${TITLE} ${YEAR}`} day={day} isDayVisible={true} />
      <div className="flex my-1">
        <div className="flex-1 text-gray-400 text-left text-sm my-0">
          Welcome <span className="font-bold">{attendanceAdmin}</span>!{" "}
        </div>
        <div className="flex-1 text-gray-400 text-right text-sm my-0">
          <a
            href="#"
            onClick={() => {
              signout(() => {
                // localStorage.removeItem("attendanceAdmin")
                // localStorage.removeItem("password")
                window.location.href = "/"
              })
            }}
            className="text-gray-400 text-right text-sm my-0"
          >
            Log out
          </a>
        </div>
      </div>
    </>
  )
}

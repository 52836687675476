import * as React from "react"
import { ALL } from "../../Services/config"
import { useFilterContext } from "../Context/FilterProvider"

interface IPropsGuide {
  isLoading: boolean
  isRefetching: boolean
  totalChecked: number
  totalExpectedStudents: number
}

export const Guide: React.FC<IPropsGuide> = ({
  isLoading,
  totalChecked,
  totalExpectedStudents,
}) => {
  const { filter, action } = useFilterContext()

  return (
    <div>
      {!isLoading && (
        <>
          {filter && (
            <div className="text-center">
              <span className="text-blue-500 font-bold ">
                Class{" "}
                {filter === ALL.value ? "Lower, Middler, and Upper" : filter}
              </span>{" "}
              <br />
              <span className="text-blue-500 font-normal">
                ({action}: {totalChecked} / {totalExpectedStudents})
              </span>
            </div>
          )}
        </>
      )}
    </div>
  )
}

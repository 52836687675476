import Autocomplete from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import type { SelectChangeEvent, TextFieldProps } from "@mui/material"

import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "../Components/Auth/AuthProvider"
import { useFilterContext } from "../Components/Context/FilterProvider"
import {
  INPUT_LOCATION_EAST,
  INPUT_LOCATION_WEST,
  TITLE,
  YEAR,
  listDaysEast,
  listDaysWest,
} from "../Services/config"
import { Main } from "./Layouts/Main"
import { ILabelValue } from "../Services/utils"
import { Header } from "../Components/Header/Header"

export const LoginPage = () => {
  const [errorPasswordMessage, setErrorPasswordMessage] = React.useState("")

  const navigate = useNavigate()
  const location = useLocation()
  const { signin, attendanceAdmin, setAttendanceAdmin } = useAuth()
  const {
    setLocation,
    location: filterLocation,
    day,
    setDay,
    isLoadingAdminList,
    filteredListAttendanceAdmins,
  } = useFilterContext()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const locState = location.state as any
  const from = locState.from?.pathname || "/"
  const searchParams = locState.from?.search || ""

  const generateDaysMenuItem = (listDays: ILabelValue[]) =>
    listDays.map((day) => (
      <MenuItem value={day.value} key={day.label}>
        {day.label}
      </MenuItem>
    ))

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const password = formData.get("password") as string

    signin(
      attendanceAdmin,
      password,
      () => {
        // localStorage.setItem("attendanceAdmin", attendanceAdmin)
        // localStorage.setItem("password", password)
        if (searchParams) navigate(`${from}${searchParams}`, { replace: true })
        else navigate(from, { replace: true })
      },
      () => {
        setErrorPasswordMessage("Please use correct passsword.")
      }
    )
  }

  const showLocationRadioButton = false

  return (
    <>
      <Header title={`${TITLE} ${YEAR}`} day={day} isDayVisible={false} />
      <Main>
        <form onSubmit={handleSubmit}>
          <div className="flex content-between flex-col my-3">
            {showLocationRadioButton && (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={filterLocation}
                  value={filterLocation}
                  onChange={(e) => {
                    setLocation(e.target.value as string)
                  }}
                >
                  <FormControlLabel
                    value={INPUT_LOCATION_WEST.value}
                    control={<Radio />}
                    label={INPUT_LOCATION_WEST.label}
                  />
                  <FormControlLabel
                    value={INPUT_LOCATION_EAST.value}
                    control={<Radio />}
                    label={INPUT_LOCATION_EAST.label}
                  />
                </RadioGroup>
              </FormControl>
            )}

            <FormControl size="small" fullWidth className="my-2">
              <InputLabel id="select-day-small">Day *</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={day}
                label="Day"
                onChange={(event: SelectChangeEvent) => {
                  setDay(event.target.value as string)
                }}
              >
                {filterLocation === INPUT_LOCATION_WEST.value
                  ? generateDaysMenuItem(listDaysWest)
                  : generateDaysMenuItem(listDaysEast)}
              </Select>
            </FormControl>

            <Autocomplete
              disablePortal
              id="adminName"
              options={filteredListAttendanceAdmins}
              onChange={(event, value, reason) => {
                if (reason === "selectOption") {
                  setAttendanceAdmin(value?.label ?? "")
                }
              }}
              loading={isLoadingAdminList}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps
              ) => <TextField {...params} label="Attendance Admin" required />}
              size="small"
            />

            <TextField
              required
              className="flex-1 my-2"
              name="password"
              type="password"
              placeholder="Enter Password"
              label="Password"
              size="small"
              helperText={errorPasswordMessage}
            />

            <Button
              className="flex-1 mt-3"
              color="primary"
              variant="contained"
              type="submit"
              size="small"
              disabled={
                attendanceAdmin === "" || filterLocation === "" || day === ""
              }
            >
              Login
            </Button>
          </div>
        </form>
      </Main>
    </>
  )
}

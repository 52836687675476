import { QueryClient, useMutation } from "react-query"
import { updateStudentApi } from "./api"
import { IStudentMutationUpdate } from "./utils"

export const useUpdateStudentMutation = (
  queryClient: QueryClient,
  onSuccessFn: (variables: IStudentMutationUpdate) => void
) =>
  useMutation(
    (studentMutationUpdate: IStudentMutationUpdate) => {
      const { newStudent, url, dataKey } = studentMutationUpdate
      const body = {
        [dataKey]: newStudent,
      }
      return updateStudentApi(url, body)
    },
    {
      onSuccess: async (data, variables) => {
        onSuccessFn(variables)
      },
    }
  )

import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import { ACTION_CHECK_OUT, ACTION_UNSELECT } from "../../Services/config"
import Warning from "@mui/icons-material/Warning"

export interface IPropsConfirmationModal {
  name: string
  urgentNote: string | undefined
  handleYes: () => void
  handleClose: () => void
  isVisibleModal: boolean
  action: string
  isUpdating: boolean
  actionModal: string
  isSignedOutGuardianVerified?: string
  isSignedIn?: string
}

export default function ConfirmationModal(props: IPropsConfirmationModal) {
  const {
    name,
    urgentNote,
    handleClose,
    isVisibleModal,
    action,
    handleYes,
    isUpdating,
    actionModal,
    isSignedOutGuardianVerified,
    isSignedIn,
  } = props

  return (
    <Dialog
      open={isVisibleModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isUpdating ? (
        <DialogTitle id="alert-dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            <CircularProgress color="success" />
          </div>
          <div className="content-center">Updating Google Sheet</div>
        </DialogTitle>
      ) : (
        <>
          <div
            style={{
              display: "flex-column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {actionModal === ACTION_UNSELECT ? "Undo" : ""}{" "}
              {actionModal === ACTION_UNSELECT ? action.toLowerCase() : action}{" "}
              <b>{name}</b> ?
            </DialogTitle>
            {urgentNote && (
              <DialogContent>
                <DialogContentText id="alert-dialog-description" color="red">
                  {urgentNote}
                </DialogContentText>
              </DialogContent>
            )}
            {action === ACTION_CHECK_OUT &&
              !isSignedOutGuardianVerified &&
              isSignedIn === "Yes" && (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Typography
                      variant="body1"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Warning
                        fontSize="small"
                        style={{ marginRight: "8px", color: "red" }}
                      />
                      Guardian has not been verified.
                    </Typography>
                  </DialogContentText>
                </DialogContent>
              )}
          </div>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="error">
              Cancel
            </Button>
            <Button variant="outlined" onClick={handleYes} autoFocus>
              {!isUpdating ? "Yes" : <CircularProgress />}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

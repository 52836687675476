import React from "react"
import {
  ALL,
  APP_PREFERENCES,
  INPUT_LOCATION_EAST,
  INPUT_LOCATION_WEST,
} from "../../Services/config"
import { getAdminList } from "../../Services/api"
import { IAttandanceLabelValue, ILabelValue } from "../../Services/utils"

interface FilterContextType {
  filter: string
  setFilter: (s: string) => void
  location: string
  setLocation: (s: string) => void
  day: string
  setDay: (s: string) => void
  searchQuery: string
  setSearchQuery: (s: string) => void
  action: string
  setAction: (s: string) => void
  filteredListAttendanceAdmins: IAttandanceLabelValue[]
  isLoadingAdminList: boolean
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const FilterContext = React.createContext<FilterContextType>(null!)

export const useFilterContext = () => {
  return React.useContext(FilterContext)
}

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [filter, setFilter] = React.useState<string>(ALL.value)
  const [location, setLocation] = React.useState<string>("")
  const [day, setDay] = React.useState<string>("")
  const [searchQuery, setSearchQuery] = React.useState("")
  const [action, setAction] = React.useState("")
  const [listAttendanceAdmins, setListAttendanceAdmins] = React.useState<
    IAttandanceLabelValue[]
  >([])
  const [filteredListAttendanceAdmins, setFilteredListAttendanceAdmins] =
    React.useState<IAttandanceLabelValue[]>([])
  const [isLoadingAdminList, setIsLoadingAdminList] =
    React.useState<boolean>(true)

  const processAdminList = () => {
    getAdminList()
      .then((res) => {
        const adminList: IAttandanceLabelValue[] = res.adminList.map(
          (row: { location: string; name: string }) => {
            const { location, name } = row

            const arrayLocations = location
              ? location.split(",").map((loc) => ({
                  label: loc,
                  value: loc,
                }))
              : []

            return {
              label: name,
              value: name,
              location: arrayLocations,
            }
          }
        )
        return adminList
      })
      .then((response) => {
        setListAttendanceAdmins(response)
        setFilteredListAttendanceAdmins(getFilteredAdminListByLocation())
        setIsLoadingAdminList(false)
      })
  }

  React.useEffect(() => {
    const { defaultLocation, defaultDay } = APP_PREFERENCES
    if (defaultLocation === INPUT_LOCATION_WEST.value) {
      setLocation(INPUT_LOCATION_WEST.value)
    } else {
      setLocation(INPUT_LOCATION_EAST.value)
    }
    processAdminList()

    // fallback mechanism if user day input is invalid
    if (
      defaultDay !== "Day 1" &&
      defaultDay !== "Day 2" &&
      defaultDay !== "Day 3"
    ) {
      setDay("Day 1")
    } else {
      setDay(defaultDay)
    }
  }, [])
  const getFilteredAdminListByLocation = () => {
    return listAttendanceAdmins.filter((list) => {
      if (
        list.location.find((loc: ILabelValue) => loc.value === location) !==
        undefined
      )
        return true
      return false
    })
  }
  // Reset state onChange location
  React.useEffect(() => {
    if (listAttendanceAdmins) {
      setFilteredListAttendanceAdmins(getFilteredAdminListByLocation())
    }
  }, [location, day, listAttendanceAdmins])

  const value = {
    filter,
    setFilter,
    location,
    setLocation,
    day,
    setDay,
    searchQuery,
    setSearchQuery,
    action,
    setAction,
    isLoadingAdminList,
    filteredListAttendanceAdmins,
  }

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}

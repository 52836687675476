import * as React from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { AuthProvider } from "./Components/Auth/AuthProvider"
import { RequireAuth } from "./Components/Auth/RequireAuth"
import { FilterProvider } from "./Components/Context/FilterProvider"
import { LoginPage } from "./Pages/LoginPage"
import MainPage from "./Pages/MainPage"
import NoPage from "./Pages/NoPage"
// import { QRPage } from "./Pages/QRPage"
import { CheckPage } from "./Pages/CheckPage"

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <FilterProvider>
          <Routes>
            <Route index element={<Navigate to="/index/all" replace />} />
            <Route
              path="classroom"
              element={<Navigate to="/index" replace />}
            />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="index/:paramFilter"
              element={
                <RequireAuth>
                  <MainPage />
                </RequireAuth>
              }
            />
            <Route
              path="check/:name"
              element={
                <RequireAuth>
                  <CheckPage />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </FilterProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}
